@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin centerCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.centerCol{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@mixin responsiveGrid{
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-content: center;
}