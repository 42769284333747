.video {
    width: 100vw;
    height: 90vh;
    // background-color: #fff;
    @include center;
    video {
        aspect-ratio: 16/9;
        border-radius: 10px;
    }

    .VidLeft {
        padding: 1rem;
        // background-color: pink;
        width: 60%;
        .VidComing {
            background: #332d2d;
            width: 100%;
        }
    }

    .VidRight{
        padding: 1rem;
        width: 40%;
        .VidGoing {
            width: 100%;
            background: #332d2d;
        }
    }

}

// responsive
@media only screen and (max-width: 850px) {
    .video{
        flex-direction: column;
        justify-content: start !important;
        div{
            width: 100% !important;
        }
        .VidGoing{
            width: 70% !important;
            margin-top: 1rem;
        }
    }
  }