@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-size: 1.3rem;
}

body {
  overflow-x: hidden;
}

.app {
  min-height: 100vh;
  background: url("../images/whiteTexture.jpeg");
}

button {
  cursor: pointer;
}

input, button, textarea {
  padding: 5px 10px;
  margin: 1rem;
  border: none;
  border: 2px solid black;
  border-radius: 20px;
  outline: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

nav {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: all 0.3s ease;
  height: 10vh;
  overflow: hidden;
}
nav img {
  width: 10vw;
  cursor: pointer;
  transition: all 0.3s ease;
}
nav img:hover {
  border-bottom: 5px dotted black;
  transform: scale(1.1);
}

.CARD {
  width: 100vw;
  height: 90vh;
  padding: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  place-content: center;
  transition: all 0.3s ease;
}
.CARD .innerCard {
  height: 200px;
  padding: 2rem;
  cursor: pointer;
  box-shadow: 1px 1px 20px grey;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CARD .innerCard:hover {
  box-shadow: 1px 1px 20px violet;
}

.video {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video video {
  aspect-ratio: 16/9;
  border-radius: 10px;
}
.video .VidLeft {
  padding: 1rem;
  width: 60%;
}
.video .VidLeft .VidComing {
  background: #332d2d;
  width: 100%;
}
.video .VidRight {
  padding: 1rem;
  width: 40%;
}
.video .VidRight .VidGoing {
  width: 100%;
  background: #332d2d;
}

@media only screen and (max-width: 850px) {
  .video {
    flex-direction: column;
    justify-content: start !important;
  }
  .video div {
    width: 100% !important;
  }
  .video .VidGoing {
    width: 70% !important;
    margin-top: 1rem;
  }
}
.message {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.message .editor {
  padding-bottom: 1rem;
}
.message .inbox {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.366);
  padding: 1rem;
  width: min(1200px, 100vw);
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.message .inbox ul {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 1rem;
  padding-bottom: 2.45rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.message .inbox ul::-webkit-scrollbar {
  width: 10px;
}
.message .inbox ul::-webkit-scrollbar-track {
  background: transparent;
}
.message .inbox ul::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.message .inbox ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.message .inbox div {
  display: flex;
  align-items: center;
}
.message .inbox div span {
  margin-left: 0.3rem;
  cursor: pointer;
}
.message .inbox li {
  width: 55%;
  height: auto;
  padding: 5px 8px;
  background-color: rgb(228, 226, 226);
  margin-bottom: 0.5rem;
  list-style: none;
  word-wrap: break-word;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.389);
}
.message .inbox .send {
  position: relative;
  left: 45%;
  bottom: 0;
  border-radius: 10px 0 10px 10px;
  background-color: rgb(113, 233, 113);
}
.message .inbox .recive {
  border-radius: 0 10px 10px 10px;
  position: relative;
  right: 0;
}

.alert {
  position: fixed;
  right: 0;
  bottom: 60px;
  width: 300px;
  height: auto;
  background-color: violet;
  padding: 1rem;
  border-radius: 10px 0 0 10px;
  box-shadow: inset 10px 0px 10px rgba(0, 0, 0, 0.44), -5px 0 20px grey;
}
.alert p {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
}

.footer {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: wheat;
  font-size: 1rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 850px) {
  * {
    font-size: 1rem;
  }
  img {
    width: 15vh !important;
  }
  .innerCard {
    height: 100px !important;
  }
  .innerCard h1 {
    scale: 1.4;
  }
  .col {
    flex-direction: column;
  }
  li {
    width: 60% !important;
  }
  .send {
    left: 40% !important;
  }
}/*# sourceMappingURL=main.css.map */