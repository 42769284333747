.message {
    width: 100vw;
    height: 90vh;
    @include centerCol;

    .editor{
        padding-bottom: 1rem;
        
       
    }

    .inbox {
        // padding-top: 20rem;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.366);
        padding: 1rem;
        width: min(1200px, 100vw);
        height: 55vh;
        @include center;
        border-radius: 10px;

        // background-color: aqua;
        ul {
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: scroll;
            padding: 1rem;
            padding-bottom: 2.45rem;


            /* width */
            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

        }

        div{
            display: flex;
            align-items: center;
            span{
                margin-left: .3rem;
                cursor: pointer;
            }
        }

        li {
            width: 55%;
            height: auto;
            padding: 5px 8px;
            background-color: rgb(228, 226, 226);
            margin-bottom: .5rem;
            list-style: none;
            word-wrap: break-word;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.389);

        }

        .send {
            position: relative;
            left: 45%;
            bottom: 0;
            border-radius: 10px 0 10px 10px;
            background-color: rgb(113, 233, 113);
        }

        .recive {
            border-radius: 0 10px 10px 10px;
            position: relative;

            right: 0;
        }
    }
}