@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
    font-size: 1.3rem;
}
body{
    overflow-x: hidden
}
.app{
    min-height: 100vh;
    // overflow: hidden;
    background: url("../images/whiteTexture.jpeg");
}
button{
    cursor: pointer;
}
input,button,textarea{
    padding: 5px 10px;
    margin: 1rem;
    border: none;
    border: 2px solid black;
    border-radius: 20px;
    outline:none;
}
// ========= mixins ===========
@import "./mixin";

//=========== nav ==============

nav{
    width: 100vw;
    @include center;
    padding: 1rem;
    transition: all .3s ease;
    height: 10vh;
    overflow: hidden;
    img{
        width: 10vw;
        cursor: pointer;
        transition: all .3s ease;

        
        &:hover{
            border-bottom: 5px dotted black;
            transform: scale(1.1);
        }
    }
}

// ========card ===============
.CARD{
    width: 100vw;
    height: 90vh;
    padding: 2rem;
    @include responsiveGrid;
    transition: all .3s ease;
    
    .innerCard{
        height: 200px;
        padding: 2rem;
        cursor: pointer;
        box-shadow: 1px 1px 20px grey;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-bottom: 2rem;
        @include centerCol;

        &:hover{
            box-shadow: 1px 1px 20px violet;
        }

    }
}


// ========= video ===========
@import "./video";

// ========= message ===========
@import "./message";

// ========basic ===============
.alert{
    position:fixed;
    right: 0;
    bottom: 60px;
    width: 300px;
    height: auto;
    background-color: violet;
    padding: 1rem;
    border-radius: 10px 0 0 10px;
    box-shadow: inset 10px 0px 10px rgba(0, 0, 0, 0.44) , -5px 0 20px grey;
    p{
        width: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        color: black;
    }
}
// footer
.footer{
    width: 100vw;
    height: 50px;
    @include center;
    background-color: wheat;
    font-size: 1rem;
    margin-top: 1rem;
}

// responsive
@media only screen and (max-width: 850px) {
    * {
        font-size: 1rem;
    }
    img{
        width: 15vh !important;
    }
    .innerCard{
        height: 100px !important;
        h1{
            scale: 1.4;
        }
    }
    .col{
        flex-direction: column;
    }
    li{
        width: 60% !important;
    }
    .send{
        left: 40% !important;
    }
  }